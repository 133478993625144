import '../style/global.css';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { ISentenceScore } from '../api/ScoreApi';
import female_avatar_1 from '../asset/female_avatar_1.jpg';
import female_avatar_2 from '../asset/female_avatar_2.jpg';
import InfoBlueIcon from '../asset/icon-info-blue.png';
import MicrophoneBlueIcon from '../asset/icon-microphone-blue.png';
import iconPronouce from '../asset/icon-pronounce-blue.png';
import SpeakerBlueIcon from '../asset/icon-speaker-blue.svg';
import male_avatar from '../asset/male_avatar.jpg';
import { useKaiTiFontStyles } from '../font/KaiTiFont';

import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { SCORE_GOOD, SCORE_OK, scoreColor } from '../Score';
import { AudioApi } from '../api/AudioApi';
import { ChatApi, IChatLine, IHskDetail } from '../api/ChatApi';
import ErrorMessageContext from '../context/ErrorMessageContext';
import ShareDialog from './ShareDialog';
import { emptyAudioBlob } from './dialogPrompt/DialogPromptComponent';


/*

conversation - intermeidlate level 
20 sentences
strucutre - vocabulary - 20.

each sentence, osmetimes 1 word, sometimes 2 or 3 words.

1 conversaiton per class

20 classes???

*/

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end', // adjust this to 'flex-start' if the message is from the other person
        margin: theme.spacing(2),
    },
    bubble: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '70%',
        backgroundColor: '#3ca6a6', // adjust this to 'grey' if the message is from the other person
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    text: {
        color: 'black',
        margin: theme.spacing(1),
    },
    avatar: {
        margin: theme.spacing(1),
    },
}));

export interface ChatBubbleProps {
    isTeacher: boolean;
    message: string;
    messageAudio: Blob | null;
    showAvatar?: boolean;
    sentenceScore?: ISentenceScore | null;
    setChatBubbleDone?: (done: boolean) => void;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement> | undefined;
    useChineseFont?: boolean;
    listenSentenceId?: number;
    chatLine?: IChatLine | null;
    showChatButton?: boolean;
    showChatPinyin?: boolean;
    isAnimated?: boolean
}

const getAudioDuration = (blob: Blob): Promise<number> => {
    if (blob == emptyAudioBlob) {
        // Return a resolved promise with duration based on message length
        return Promise.resolve(0);
    }
    return new Promise((resolve, reject) => {
        const audio = new Audio(URL.createObjectURL(blob));

        // Set up event listeners
        audio.addEventListener('loadedmetadata', () => {
            resolve(audio.duration);
        });

        audio.addEventListener('error', (e) => {
            reject(new Error("Failed to load audio metadata."));
        });
    });
};



const ChatBubble = ({ isTeacher, message, messageAudio = null, showAvatar = true, sentenceScore = null, setChatBubbleDone,
    chatBubbleContainerRef, useChineseFont = false, listenSentenceId = -1, chatLine = null, showChatButton = false, showChatPinyin = false, isAnimated = true }: ChatBubbleProps) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    if (message == undefined || message == null || message.trim().length <= 0) {
        if (setChatBubbleDone) {
            console.log("chat bubbone done 3")
            setChatBubbleDone(true);
        }
        return null;
    };

    const [messageAudioDuration, setMessageAudioDuration] = React.useState<number | null>(null);
    const [hasAudio, setHasAudio] = React.useState(false);
    const [hasAudioDonePlaying, setHasAudioDonePlaying] = React.useState(false);

    const playAudio = (audioData: Blob | null) => {
        if (audioData && audioData == emptyAudioBlob) {
            // 404, audio not found. just do nothing
        } else if (audioData) {
            const audio = new Audio(URL.createObjectURL(audioData));
            audio.addEventListener("loadedmetadata", () => {
                audio.play();
            })
            audio.addEventListener("error", (err) => {
                console.log("audio done playing 1")
                setHasAudioDonePlaying(true);
            })
            audio.addEventListener("ended", () => {
                console.log("audio done playing 2 for message " + message)
                setHasAudioDonePlaying(true)
            })
            setHasAudio(true)
        }
    };
    
    React.useEffect(() => {
        if (messageAudio != null) {
            getAudioDuration(messageAudio)
                .then(duration => {
                    setMessageAudioDuration(duration)
                })
                .catch(error => {
                    setMessageAudioDuration(0);
                    console.error("Error fetching audio duration:", error);
                    setErrorMessage("Error fetching audio duration:" + error);
                })
                .finally(() => {
                    playAudio(messageAudio);
                });
        }
    }, [messageAudio]);

    const classes = useStyles();

    const bubbleRef = React.useRef<HTMLDivElement>(null);

    const studentName = localStorage.getItem('studentName');
    const studentAvatar = localStorage.getItem('studentAvatar');
    const teacher = localStorage.getItem('teacher');
    let teacherAvatar;

    if (teacher == "male") {
        teacherAvatar = male_avatar
        message = message.replace(/<TeacherName\/>/gi, "Shi Laoshi");
    } else if (teacher == 'female') {
        teacherAvatar = female_avatar_1
        message = message.replace(/<TeacherName\/>/gi, "Hou Laoshi");
    } else if (teacher == 'female2') {
        teacherAvatar = female_avatar_2
        message = message.replace(/<TeacherName\/>/gi, "Fan Laoshi");
    } else if (teacher == null) {
        teacherAvatar = null
        message = message.replace(/<TeacherName\/>/gi, "your teacher");
    } else {
        throw Error("unknown selected teacher name in local storage " + teacher);
    }

    const theme = useTheme();

    const justifyContent = isTeacher ? 'flex-start' : 'flex-end';
    const backgroundColor = isTeacher ? '#F2F7FF' : '#FFFCF5';

    if (studentName) {
        message = message.replace(/<StudentName\/>/gi, studentName);
    }

    message = message.replace(/<Answer\/>/gi, "_________");

    const handleSpeakerClick = (sentenceId: number) => {
        const teacher = localStorage.getItem('teacher');
        let audioPromise;

        if (teacher == "male") {
            audioPromise = AudioApi.getMale(sentenceId)
        } else if (teacher == 'female') {
            audioPromise = AudioApi.getFemale(sentenceId)
        } else if (teacher == 'female2') {
            audioPromise = AudioApi.getFemale2(sentenceId)
        } else {
            throw Error("unknown selected teacher name in local storage " + teacher);
        }


        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.play();
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    };

    const [hskDetailList, setHskDetailList] = React.useState<IHskDetail[] | null>(null);
    const [isChatLineDetailDialogOpen, setIsChatLineDetailDialogOpen] = React.useState(false);

    const handleChatLineDetailClick = (chatLineId: number) => {
        ChatApi.getHskDetailDetail(chatLineId) // Assuming `id` is the correct property to identify the chat line.
            .then(response => {
                // Assuming the response data structure matches what you want to display
                setHskDetailList(response.data);
                setIsChatLineDetailDialogOpen(true);
            })
            .catch(error => {
                console.error("Error fetching chat line detail:", error);
                // Handle the error state appropriately. Maybe set an error message or log it.
            });
    }

    const handlePronounceClick = (chatLineId: number) => {
        ChatApi.getChatLine(chatLineId) // Assuming `id` is the correct property to identify the chat line.
            .then(response => {
                const chatLine = response.data
                history.push(`/sentence?text=` + chatLine.line);
            })
            .catch(error => {
                console.error("Error fetching chat line detail:", error);
                // Handle the error state appropriately. Maybe set an error message or log it.
            });
    };


    const [startTime, setStartTime] = React.useState(Date.now());

    const [displayedMessage, setDisplayedMessage] = React.useState('');
    const [displayedMessageSegments, setDisplayedMessageSegments] = React.useState<string[]>([]);
    // const [chatBubbleHeight, setChatBubbleHeight] = React.useState(0);


    // count rows needed for sentenceScore
    const stackRef = React.useRef<HTMLDivElement>(null); // Ref for the row stack container
    const [numRows, setNumRows] = React.useState(1);
    const [rowElements, setRowElements] = React.useState<string[][]>([[]]);
    const [elementsPerRow, setElementsPerRow] = React.useState(50);

    const [currentIndex, setCurrentIndex] = React.useState(0);

    const chatBubbleFont = useKaiTiFontStyles("black", '1.5rem');
    const chatFont = useChineseFont ? chatBubbleFont.customFont : classes.text;

    const kaiTiFontClass = useKaiTiFontStyles();
    const storedLearnWithCharacter = localStorage.getItem('learnWithCharacter');
    const learnWithCharacter = storedLearnWithCharacter ? JSON.parse(storedLearnWithCharacter) : false;

    React.useEffect(() => {

        if (message == undefined && hasAudio == false) {
            if (setChatBubbleDone) {
                console.log("chat bubbone done 1")
                setChatBubbleDone(true);
            }
            return;
        }

        if (messageAudioDuration != null && messageAudioDuration <= 0) {
            setMessageAudioDuration(message.length * 0.025);
        } else if (message && messageAudioDuration) {

            var delay = 333; // initial delay for initial pause in audio
            if (currentIndex > 0) {
                const expectedTime = delay + (currentIndex / message.length) * (messageAudioDuration * 1000 - delay);
                const nowInMilliseconds = Date.now();
                const timeSoFar = nowInMilliseconds - startTime;

                const r = Math.random();
                var randomNumber = (r * 20 - 10) * (r * 20 - 10) - 50;
                delay += randomNumber;

                delay = Math.max(0, expectedTime - timeSoFar)
            }

            // if (message.substring(0, currentIndex).endsWith("<Microphone/>")) {
            //     randomNumber += 250;
            // } else if (message.substring(0, currentIndex).endsWith("<Speaker/>")) {
            //     randomNumber += 250;
            // } else if (message.substring(0, currentIndex).endsWith("<Info/>")) {
            //     randomNumber += 250;
            // }

            if (isAnimated == false) {
                delay = 25
            }
            const timer = setInterval(() => {
                if (message) {
                    if (isAnimated) {
                        setDisplayedMessage((prevMessage) => {
                            if (message) {
                                if (currentIndex >= message.length) {
                                    return prevMessage;
                                } else if (message.substring(currentIndex).startsWith("<Microphone/>")) {
                                    return prevMessage + "<Microphone/>";
                                } else if (message.substring(currentIndex).startsWith("<Speaker/>")) {
                                    return prevMessage + "<Speaker/>";
                                } else if (message.substring(currentIndex).startsWith("<Info/>")) {
                                    return prevMessage + "<Info/>";
                                }
                                return prevMessage + message[currentIndex];
                            }
                            return "";
                        });
                        if (message.substring(currentIndex).startsWith("<Microphone/>")) {
                            setCurrentIndex(prev => prev + "<Microphone/>".length);
                        } else if (message.substring(currentIndex).startsWith("<Speaker/>")) {
                            setCurrentIndex(prev => prev + "<Speaker/>".length);
                        } else if (message.substring(currentIndex).startsWith("<Info/>")) {
                            setCurrentIndex(prev => prev + "<Info/>".length);
                        } else {
                            setCurrentIndex(prev => prev + 1);
                        }

                    } else {
                        setDisplayedMessage(message)
                        setCurrentIndex(message.length)
                        calculateNumRows()
                    }
                }

                clearInterval(timer);
            }, delay); // Adjust the delay between each letter here (in milliseconds)

            return () => {
                clearInterval(timer);
            };

        }
    }, [message, displayedMessageSegments, messageAudioDuration]);

    React.useEffect(() => {
        let messageTextDone = true;
        if (message) messageTextDone = currentIndex >= message.length

        let audioDone = true;
        if (hasAudio) audioDone = hasAudioDonePlaying

        if (messageTextDone && audioDone && setChatBubbleDone) {
            console.log("chat bubbone done 2 for message " + message)
            setChatBubbleDone(true);
        }
    }, [currentIndex, message, hasAudio, hasAudioDonePlaying])

    // // Regular expression to match Chinese characters
    // const chineseRegex = /[\u4e00-\u9fa5，。！？；：、“”‘’【】（）《》·-——【】|]+/g;
    // const chineseRegex = /[\p{Script=Han}]+/gu;
    // // Regular expression to match English characters
    // \x00-\x7F: Basic ASCII range.
    // \u00C0-\u017F: This range includes Latin Extended-A, covering many accented characters used across various European languages.
    // \u0100-\u024F: Latin Extended-A and Latin Extended-B.
    // \u1E00-\u1EFF: Latin Extended Additional.

    const anyEnglishRegex = /[a-zA-Z]/;
    const englishRegex = /[\x00-\x7F\u00C0-\u017F\u0100-\u024F\u1E00-\u1EFFāáǎàēéěèīíǐìōóǒòūúǔùǖǘǚǜ]+/g;
    React.useEffect(() => {
        // Split the displayedMessage into segments based on Chinese characters
        const segments = [];
        let match;
        let currentIndex = 0;

        while ((match = englishRegex.exec(displayedMessage)) !== null) {
            if (currentIndex !== match.index) {
                segments.push(displayedMessage.slice(currentIndex, match.index)); // Push Chinese text
            }
            const englishText = match[0];
            // Split the string using regular expressions to capture either "<Microphone/>" or "<Speaker/>" as the delimiter
            const splitArray = englishText.split(/(<Microphone\/>|<Speaker\/>|<Info\/>)/);
            segments.push(...splitArray); // Push English text
            currentIndex = englishRegex.lastIndex;
        }

        if (currentIndex <= displayedMessage.length) {
            segments.push(displayedMessage.slice(currentIndex)); // Push remaining Chinese text
        }

        setDisplayedMessageSegments(segments)

    }, [displayedMessage])

    const calculateNumRows = () => {
        let newNumRows = 1;
        if (stackRef.current && stackRef.current.parentElement && stackRef.current.parentElement.parentElement) {
            const stackWidth = stackRef.current.parentElement.offsetWidth;
            if (stackRef.current.children && stackRef.current.children.length > 0) {
                let rowWidth = 0;
                // console.log(message + " / " + displayedMessage + " / " + stackRef.current.children.length)
                for (let i = 0; i < stackRef.current.children.length; i++) {
                    if (stackRef.current.children[i].children) {
                        const stackChildren = stackRef.current.children[i].children;
                        for (let j = 0; j < stackChildren.length; j++) {
                            const child = stackChildren[j] as HTMLElement;
                            const childStyles = getComputedStyle(child as HTMLElement);
                            const childWidth = (child as HTMLElement).offsetWidth +
                                parseFloat(childStyles.paddingLeft) +
                                parseFloat(childStyles.paddingRight) +
                                parseFloat(childStyles.marginLeft) +
                                parseFloat(childStyles.marginRight);
                            rowWidth += childWidth;
                        }
                    }
                }
                newNumRows = Math.ceil(rowWidth / stackWidth);
            }
        }
        setNumRows(newNumRows);
    };


    // Wrap text if it is too long
    // this is for when isAnimted = true, and we see charadter by character
    React.useEffect(() => {
        // Call the handleResize function initially and add event listener for resize
        calculateNumRows();
        window.addEventListener('resize', calculateNumRows);

        return () => {
            window.removeEventListener('resize', calculateNumRows);
        };
    }, [displayedMessage, currentIndex]);

    // Wrap text if it is too long
    // this is for when isAnimated = false, and we see entire bubble at once
    React.useEffect(() => {
        if (!stackRef.current) return; // Early exit if ref is not assigned yet

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    // Handle changes in children
                    calculateNumRows();
                }
            });
        });

        // Observe direct children additions or removals
        observer.observe(stackRef.current, {
            childList: true // Configure it to watch for direct children changes
        });

        // Clean up observer on component unmount or ref changes
        return () => {
            observer.disconnect();
        };
    }, [stackRef.current]); // Note: this only re-runs if the ref itself changes, not its children

    React.useEffect(() => {
        if (sentenceScore && sentenceScore.audioData) {
            getAudioDuration(sentenceScore.audioData)
                .then(duration => {
                    setMessageAudioDuration(duration)
                })
                .catch(error => {
                    setMessageAudioDuration(0);
                    console.error("Error fetching audio duration:", error);
                    setErrorMessage("Error fetching audio duration:" + error);
                })
                .finally(() => {
                    playAudio(messageAudio);
                });
            playAudio(sentenceScore.audioData)
        }
    }, [sentenceScore])

    React.useEffect(() => {
        if (sentenceScore && sentenceScore!.text_pinyin) {
            const nonNullPinyin = sentenceScore!.text_pinyin; // Hold the non-null value
            const newElementsPerRow = Math.ceil(nonNullPinyin.length / numRows);
            setElementsPerRow(newElementsPerRow);
            const rows = Array.from({ length: numRows }, (_, i) =>
                nonNullPinyin.slice(i * newElementsPerRow, (i + 1) * newElementsPerRow)
            );
            setRowElements(rows);
            // } else {
            //     // If dialogPrompt.pinyin is null or undefined, set an empty array to clear the rows
            //     setRowElements([]);
        }
    }, [sentenceScore, numRows]);

    React.useEffect(() => {
        // chatLine rowElements will contain the sentence itself
        // not all chatLine contains pinyin.
        if (chatLine && chatLine!.line) {
            // const nonNullPinyin = chatLine!.pinyin; // Hold the non-null value
            const nonNullLine = Array.from(chatLine!.line); // Hold the non-null value

            const newElementsPerRow = Math.ceil(nonNullLine.length / numRows);
            setElementsPerRow(newElementsPerRow);

            // const rowsPinyin = Array.from({ length: numRows }, (_, i) =>
            //     nonNullPinyin.slice(i * newElementsPerRow, (i + 1) * newElementsPerRow)
            // );
            const rowsLine = Array.from({ length: numRows }, (_, i) =>
                nonNullLine.slice(i * newElementsPerRow, (i + 1) * newElementsPerRow)
            );
            setRowElements(rowsLine);
            // } else {
            //     // If dialogPrompt.pinyin is null or undefined, set an empty array to clear the rows
            //     setRowElements([]);
        }
    }, [chatLine, numRows]);

    React.useEffect(() => {
        if (chatBubbleContainerRef && chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [displayedMessage])

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const [isShareDialogOpen, setIsShareDialogOpen] = React.useState(false);

    const handleOpenShareDialog = () => {
        setIsDialogOpen(false);
        setIsShareDialogOpen(true);
    };


    function chatBubbleMessage() {

        if (sentenceScore) {
            return <>
                <div ref={stackRef}>
                    {rowElements.map((dialogPinyin, rowIndex) =>
                        <Stack direction="row" key={rowIndex} spacing={1} alignItems="center" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {dialogPinyin.map((pinyinElement, index) =>
                                <Stack alignItems="center" justifyContent="center" key={index}>
                                    {/* if green/good score, always show correct pinyin, else spoken pinyin */}
                                    {sentenceScore!.score[rowIndex * elementsPerRow + index] >= SCORE_GOOD ? (
                                        <Typography style={{ color: scoreColor(sentenceScore!.score[rowIndex * elementsPerRow + index]), marginLeft: '0.5em', marginRight: '0.5em' }}>
                                            {pinyinElement}</Typography>
                                    ) : (
                                        <Typography style={{ color: scoreColor(sentenceScore!.score[rowIndex * elementsPerRow + index]), marginLeft: '0.5em', marginRight: '0.5em' }}>
                                            {sentenceScore!.likely_pinyin[rowIndex * elementsPerRow + index] ? sentenceScore!.likely_pinyin[rowIndex * elementsPerRow + index] : '-'}
                                        </Typography>
                                    )}
                                    {/* <Typography style={{ color: scoreColor(sentenceScore!.score[rowIndex * elementsPerRow + index]), marginLeft: '0.5em', marginRight: '0.5em' }}>
                                {pinyinElement}
                            </Typography> */}
                                    {learnWithCharacter && (
                                        <Typography className={kaiTiFontClass.customFont} style={{ color: scoreColor(sentenceScore!.score[rowIndex * elementsPerRow + index]), fontSize: '24px' }}>
                                            {sentenceScore!.text[rowIndex * elementsPerRow + index]}</Typography>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    )}
                </div>
                {/* Add the icon button */}
                <IconButton onClick={handleDialogOpen}>
                    <img src={InfoBlueIcon} style={{ width: '36px', height: '36px' }} />
                    {/* <InfoRoundedIcon fontSize="large" /> */}
                </IconButton>
                {/* Dialog component */}
                <Dialog open={isDialogOpen} onClose={handleDialogClose}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'background.default' // Assuming 'background' is an object with a 'default' property
                        }
                    }}
                >
                    <DialogTitle><Typography variant="h4">Spoken Accuracy</Typography></DialogTitle>
                    <DialogContent>
                        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
                            <Stack direction="row" className='border-light' sx={{ p: 2, mb: 2 }}>
                                <Typography style={{ color: scoreColor(100), marginLeft: '0.5em', marginRight: '0.5em' }}>
                                    Wonderful
                                </Typography>
                                <Typography style={{ color: scoreColor(SCORE_OK + 1), marginLeft: '0.5em', marginRight: '0.5em' }}>
                                    So-So
                                </Typography>
                                <Typography style={{ color: scoreColor(0), marginLeft: '0.5em', marginRight: '0.5em' }}>
                                    Try Again
                                </Typography>
                            </Stack>
                            {rowElements.map((dialogPinyin, rowIndex) =>
                                <Stack direction="row" key={rowIndex} spacing={1} alignItems="center" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                    {dialogPinyin.map((pinyinElement, index) =>
                                        <Stack alignItems="center" justifyContent="center" key={index}>
                                            {/* if green/good score, always show correct pinyin, else spoken pinyin */}
                                            {sentenceScore!.score[rowIndex * elementsPerRow + index] >= SCORE_GOOD ? (
                                                <Typography style={{ color: scoreColor(sentenceScore!.score[rowIndex * elementsPerRow + index]), marginLeft: '0.5em', marginRight: '0.5em' }}>
                                                    {pinyinElement}</Typography>
                                            ) : (
                                                <Typography style={{ color: scoreColor(sentenceScore!.score[rowIndex * elementsPerRow + index]), marginLeft: '0.5em', marginRight: '0.5em' }}>
                                                    {sentenceScore!.likely_pinyin[rowIndex * elementsPerRow + index] ? sentenceScore!.likely_pinyin[rowIndex * elementsPerRow + index] : '-'}
                                                </Typography>
                                            )}
                                            { /* show character */}
                                            {learnWithCharacter && (
                                                <Typography className={kaiTiFontClass.customFont} style={{ color: scoreColor(sentenceScore!.score[rowIndex * elementsPerRow + index]), fontSize: '24px' }}>
                                                    {sentenceScore!.text[rowIndex * elementsPerRow + index]}</Typography>
                                            )}
                                            {/* show correct pinyin */}
                                            <Typography style={{ color: scoreColor(SCORE_GOOD + 1) }}>
                                                {pinyinElement}
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>
                            )}
                            <IconButton onClick={() => playAudio(sentenceScore.audioData)}>
                                <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px' }} />
                                {/* <VolumeUpRoundedIcon fontSize="large" /> */}
                            </IconButton>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                        <Button onClick={handleOpenShareDialog} variant="contained" sx={{ mr: 1 }} className='secondaryButton'>Share</Button>
                        {/* Adding margin right to the first button to ensure some space between the two buttons */}
                        <Button onClick={handleDialogClose} variant="contained">Close</Button>
                    </DialogActions>
                </Dialog>
                {sentenceScore.sentence_id && (
                    <ShareDialog
                        isOpen={isShareDialogOpen}
                        onClose={() => setIsShareDialogOpen(false)}
                        sentenceScoreId={sentenceScore.id} />
                )}
            </>
        }
        if (chatLine) {
            return <>
                <div ref={stackRef}>
                    {rowElements.map((dialogLine, rowIndex) =>
                        <Stack direction="row" key={rowIndex} spacing={1} alignItems="center" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                            {dialogLine.map((lineElement, index) => {
                                if (rowIndex * elementsPerRow + index <= currentIndex) {

                                    // Determine if the lineElement contains Chinese characters
                                    // this is a proxy if this is Conversation or Office Hours chat.
                                    const lineElement = chatLine!.line[rowIndex * elementsPerRow + index];
                                    const containsEnglish = anyEnglishRegex.test(lineElement) || lineElement == "";

                                    // Apply appropriate font based on whether it's Chinese or English
                                    const font = containsEnglish ? chatFont : kaiTiFontClass.customFont; // Replace with actual font names
                                    // Apply a font size of 24px for Chinese segments
                                    const fontSize = containsEnglish ? 'inherit' : '24px';

                                    return <Stack alignItems="center" justifyContent="center" key={index}>
                                        {showChatPinyin && (
                                            <Typography style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>
                                                {/* {pinyinElement} */}
                                                {chatLine!.pinyin[rowIndex * elementsPerRow + index]}
                                            </Typography>
                                        )}
                                        <Typography className={font} style={{ fontSize }}>
                                            {/* {chatLine!.line[rowIndex * elementsPerRow + index]} */}
                                            {lineElement}
                                        </Typography>
                                    </Stack>
                                }
                                return;
                            })}
                        </Stack>
                    )}
                </div >
            </>
        }
        return <div className="message-container"> {/* Wrap the segments in a container */}
            {
                displayedMessageSegments.map((segment, index) => {
                    // Determine if the segment contains Chinese characters
                    const isEnglish = englishRegex.test(segment) || segment == "";

                    // Apply appropriate font based on whether it's Chinese or English
                    const font = isEnglish ? chatFont : kaiTiFontClass.customFont; // Replace with actual font names
                    // Apply a font size of 24px for Chinese segments
                    const fontSize = isEnglish ? 'inherit' : '24px';

                    if (segment == '<Microphone/>') {
                        return <img key={index} src={MicrophoneBlueIcon} style={{ width: '18px', height: '18px' }} />
                    } else if (segment.includes('<Speaker/>')) {
                        return <img key={index} src={SpeakerBlueIcon} style={{ width: '18px', height: '18px' }} />
                    } else if (segment.includes('<Info/>')) {
                        return <img key={index} src={InfoBlueIcon} style={{ width: '18px', height: '18px' }} />
                    }
                    return (
                        <Typography key={index} className={font} style={{ fontSize, verticalAlign: 'middle' }} display="inline" >
                            {segment.trim()}
                        </Typography>
                    );
                })
            }
        </div>
    }


    return (<>
        {messageAudioDuration != null && (
            <div className={classes.root} style={{ justifyContent }}>
                {isTeacher ? (
                    <>
                        {showAvatar ? (
                            <img
                                src={teacherAvatar || ''}
                                style={{ maxHeight: '4em', maxWidth: '4em' }}
                            />
                        ) : null}
                        {(listenSentenceId > 0) && (
                            <IconButton onClick={() => handleSpeakerClick(listenSentenceId)}>
                                <img src={SpeakerBlueIcon} style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {(showChatButton && chatLine) && (
                                <IconButton onClick={() => handleChatLineDetailClick(chatLine.id)}>
                                    <img src={InfoBlueIcon} style={{ width: '30px', height: '30px' }} />
                                </IconButton>
                            )}
                            {(showChatButton && chatLine) && (
                                <IconButton onClick={() => handlePronounceClick(chatLine.id)}>
                                    <img src={iconPronouce} style={{ width: '24px', height: '24px' }} />
                                </IconButton>
                            )}
                        </div>
                        <div className={`${classes.bubble} border-light`} style={{ backgroundColor }} ref={bubbleRef}>
                            {chatBubbleMessage()}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={`${classes.bubble} border-light`} style={{ backgroundColor }} ref={bubbleRef}>
                            {chatBubbleMessage()}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {(showChatButton && chatLine) && (
                                <IconButton onClick={() => handleChatLineDetailClick(chatLine.id)}>
                                    <img src={InfoBlueIcon} style={{ width: '30px', height: '30px' }} />
                                </IconButton>
                            )}
                            {(showChatButton && chatLine) && (
                                <IconButton onClick={() => handlePronounceClick(chatLine.id)}>
                                    <img src={iconPronouce} style={{ width: '24px', height: '24px' }} />
                                </IconButton>
                            )}
                        </div>
                        {(listenSentenceId > 0) && (
                            <IconButton onClick={() => handleSpeakerClick(listenSentenceId)}>
                                <img src={SpeakerBlueIcon} style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                        )}
                        {showAvatar ? (
                            <img
                                src={studentAvatar || ''}
                                style={{ maxHeight: '4em', maxWidth: '4em' }}
                            />
                        ) : null}
                    </>
                )
                }
            </div>
        )}
        {/* Chat Line Detail Dialog */}
        <Dialog open={isChatLineDetailDialogOpen} onClose={() => setIsChatLineDetailDialogOpen(false)}>
            <DialogTitle>Sentence Detail</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper} sx={{
                    border: '2px solid lightgrey',
                    borderRadius: '8px',
                    overflow: 'hidden', // Ensures the borderRadius applies to the table as well
                    borderColor: 'lightgrey', // Explicitly setting the border color
                    // Additional styles to enforce the border color if necessary
                    '& .MuiPaper-root': {
                        borderColor: 'lightgrey',
                    }
                }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Word</TableCell>
                                <TableCell align="right">HSK Level</TableCell>
                                <TableCell align="right">Pinyin</TableCell>
                                <TableCell align="right">Definition</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hskDetailList ? hskDetailList.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">{row.word}</TableCell>
                                    <TableCell align="right">{row.hsk_level}</TableCell>
                                    <TableCell align="right">{row.pinyin}</TableCell>
                                    <TableCell align="right">{row.definition}</TableCell>
                                </TableRow>
                            )) : <TableRow><TableCell colSpan={4} align="center">Loading...</TableCell></TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsChatLineDetailDialogOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </>);
};

export default ChatBubble;
