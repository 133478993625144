import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import './style/global.css'; // Import global CSS file
import theme from './theme';

// react 17
import React from 'react'; // This tells ESLint to ignore the "unused import" rule for React
import ReactDOM from 'react-dom';

// Fake usage of React to prevent it from being removed by the linter
const _unusedReact = React;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
