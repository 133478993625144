import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Button, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';

import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogPlanApi, IDialogPlan } from '../api/DialogPlanApi';
import { DialogPlanLlmPrompt, DialogPlanLlmPromptApi } from '../api/DialogPlanLlmPromptApi';
import DialogPlanForm from '../component/dialogPlan/DialogPlanForm';
import DialogPlanLlmPromptForm from '../component/dialogPlan/DialogPlanLlmPromptForm';
import ErrorMessageContext from '../context/ErrorMessageContext';

export default function DialogPlan() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [dialogPlanList, setDialogPlanList] = React.useState<IDialogPlan[]>([]);
    const [selectedDialogPlan, setSelectedDialogPlan] = React.useState<IDialogPlan | null>(null);

    const [dialogPlanLlmPromptList, setDialogPlanLlmPromptList] = React.useState<DialogPlanLlmPrompt[]>([]);
    const [selectedDialogPlanLlmPrompt, setSelectedDialogPlanLlmPrompt] = React.useState<DialogPlanLlmPrompt | null>(null);

    const history = useHistory();

    React.useEffect(() => {
        DialogPlanApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPlanApi.getAll() " + response.status)
            })
            .then(list => setDialogPlanList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
        DialogPlanLlmPromptApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPlanLlmPromptApi.getAll() " + response.status)
            })
            .then(list => setDialogPlanLlmPromptList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }, []);

    /*
        DIALOGPLAN HANDLERS
     */

    const handleExpandDialogPlan = (dialogPlanId: number) => {
        setDialogPlanList((prevDialogPlanList) =>
            prevDialogPlanList.map((dialogPlan) =>
                dialogPlan.id === dialogPlanId ? { ...dialogPlan, expanded: !dialogPlan.expanded } : dialogPlan
            )
        );
    };

    const handleAddDialogPlan = async () => {
        const newDialogPlan: IDialogPlan = {
            id: 0,
            name: 'New DialogPlan Name',
            dialog_title: 'new dialog title'
        };
        DialogPlanApi.create(newDialogPlan)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return null
            })
            .then(newDialogPlanWithId => {
                setSelectedDialogPlan(newDialogPlanWithId)
                setSelectedDialogPlanLlmPrompt(null)
                // setSelectedDialog(null)
                // setSelectedDialogPrompt(null)
                DialogPlanApi.getAll().then(response => {
                    setDialogPlanList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    };

    const handleDialogPlanMenuClick = (dialogPlan: IDialogPlan) => {
        setSelectedDialogPlan(dialogPlan)
        setSelectedDialogPlanLlmPrompt(null)
        // setSelectedDialog(null)
        // setSelectedDialogPrompt(null)
        handleExpandDialogPlan(dialogPlan.id)
    };

    const handleDialogPlanFormSubmit = async (updatedDialogPlan: IDialogPlan) => {
        DialogPlanApi.update(updatedDialogPlan)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(result => {
                DialogPlanApi.getAll().then(response => {
                    setDialogPlanList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    };

    const handleDeleteDialogPlan = async (dialogPlanId: number) => {
        DialogPlanApi.delete(dialogPlanId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(list => {
                setSelectedDialogPlan(null)
                DialogPlanApi.getAll().then(response => {
                    setDialogPlanList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }

    /*
        DIALOG PROMPT HANDLERS
     */

    const handleAddDialogPlanLlmPrompt = async (dialogPlanId: number) => {
        const largestOrderIndex = dialogPlanLlmPromptList.reduce((maxOrderIndex, dialogPrompt) => {
            return Math.max(maxOrderIndex, dialogPrompt.order_index);
        }, 0);
        const newDialogPrompt: DialogPlanLlmPrompt = {
            id: 0,
            class_name: 'DialogPlanLlmPrompt',
            dialog_plan_id: dialogPlanId,
            order_index: Math.ceil(largestOrderIndex + 1),
            is_teacher: true,
            prompt: 'New prompt',
            is_note_to_student: false,
        };
        DialogPlanLlmPromptApi.create(newDialogPrompt)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return null
            })
            .then(newDialogPromptWithId => {
                setSelectedDialogPlan(null)
                setSelectedDialogPlanLlmPrompt(newDialogPromptWithId)
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    };

    const handleDialogPlanLlmPromptMenuClick = (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => {
        setSelectedDialogPlan(null)
        setSelectedDialogPlanLlmPrompt(dialogPlanLlmPrompt)
        // setSelectedDialog(null)
        // setSelectedDialogPrompt(dialogPrompt)
    };

    const handleDialogPlanLlmPromptFormSubmit = async (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => {

        DialogPlanLlmPromptApi.update(updatedDialogPlanLlmPrompt)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(response => {
                DialogPlanLlmPromptApi.getAll().then(response => {
                    setDialogPlanLlmPromptList(response.data)
                })
                setIsSaving(false)
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    if (err.response && err.response['data'] && err.response['data'].startsWith("'Unsupported pinyin:")) {
                        setErrorMessage(err.response['data']);
                    } else {
                        setErrorMessage(err.message);
                    }
                }
            })
    };

    const handleDeleteDialogPlanLlmPrompt = async (dialogPlanLlmPromptId: number) => {
        DialogPlanLlmPromptApi.delete(dialogPlanLlmPromptId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(list => {
                setSelectedDialogPlanLlmPrompt(null)
                DialogPlanLlmPromptApi.getAll().then(response => {
                    setDialogPlanLlmPromptList(response.data)
                })
           })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }

    return (
        <Container sx={{ mx: 2, my: 2 }} maxWidth={false}>
            <Grid container justifyContent="center">
                {/* <Grid item sm={12} md={6} alignItems="center" justifyContent="center" sx={{ marginTop: 2 }}> */}
                {/* Left Grid with fixed width */}
                <Grid item sx={{ width: '480px', marginTop: 2 }}>
                    <Stack spacing={2}>
                        {dialogPlanList.map(dialogPlan => (<div key={dialogPlan.id}>
                            <Stack direction="row" alignItems="center">
                                <IconButton onClick={() => handleExpandDialogPlan(dialogPlan.id)}>
                                    {dialogPlan.expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                                </IconButton>
                                <MenuBookRoundedIcon sx={{ mr: 2 }} />
                                <Typography onClick={() => handleDialogPlanMenuClick(dialogPlan)}>
                                    {dialogPlan.name}
                                </Typography>
                            </Stack>
                            {dialogPlan.expanded == true ? (
                                <Stack>
                                    {dialogPlanLlmPromptList
                                        .filter(dialogPlanLlmPrompt => dialogPlanLlmPrompt.dialog_plan_id === dialogPlan.id)
                                        .map(dialogPlanLlmPrompt => (<div key={dialogPlanLlmPrompt.id}>
                                            <Stack direction="row" alignItems="center" sx={{ ml: 8 }}>
                                                {/* <IconButton onClick={() => handleExpandDialogPlanLlmPrompt(dialogPlanLlmPrompt.id)}>
                                                    {dialogPlanLlmPrompt.expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                                                </IconButton> */}
                                                <ArticleRoundedIcon sx={{ mr: 2 }} />
                                                <Typography onClick={() => handleDialogPlanLlmPromptMenuClick(dialogPlanLlmPrompt)}>
                                                    {dialogPlanLlmPrompt.prompt && dialogPlanLlmPrompt.prompt.length > 20
                                                        ? `${dialogPlanLlmPrompt.prompt.substring(0, 40)} ...`
                                                        : dialogPlanLlmPrompt.prompt && dialogPlanLlmPrompt.prompt.length > 0
                                                            ? dialogPlanLlmPrompt.prompt
                                                            : dialogPlanLlmPrompt.class_name}
                                                </Typography>
                                            </Stack>
                                        </div>))
                                    }
                                    <Button variant="outlined" onClick={() => handleAddDialogPlanLlmPrompt(dialogPlan.id)} sx={{ width: 'fit-content', mt: 2, ml: 8 }}>
                                        Add DialogPlanLlmPrompt
                                    </Button>
                                </Stack>
                            ) : null}
                        </div>))}
                        <Button variant="outlined" onClick={handleAddDialogPlan} sx={{ width: 'fit-content', ml: 4 }}>
                            Add DialogPlan
                        </Button>
                    </Stack>
                </Grid>
                {/* <Grid item sm={12} md={6} alignItems="center" justifyContent="center" component={Paper} elevation={6}> */}
                {/* Right Grid that takes the remaining space */}
                <Grid item sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }} component={Paper} elevation={6}>
                    {selectedDialogPlan != null ? (
                        <DialogPlanForm dialogPlanId={selectedDialogPlan.id} onSubmit={handleDialogPlanFormSubmit} onDelete={handleDeleteDialogPlan} />
                    ) : null}
                    {selectedDialogPlanLlmPrompt != null ? (
                        <DialogPlanLlmPromptForm dialogPlanLlmPromptId={selectedDialogPlanLlmPrompt.id} onSubmit={handleDialogPlanLlmPromptFormSubmit} onDelete={handleDeleteDialogPlanLlmPrompt} />
                    ) : null}
                </Grid>
            </Grid>
        </Container>
    );
}
