import { Box, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { isJwtLoggedIn } from '../Menu';
import background from '../asset/background-signup.png';

export default function Home() {
    const location = useLocation();
    const [isLoggedIn, setLoggedIn] = React.useState(false);

    React.useEffect(() => {
        if (isJwtLoggedIn()) {
            setLoggedIn(true)
        }

    }, []);
    React.useEffect(() => {
        // Function to get the value of the "refer" parameter from the URL
        const getQueryParam = (param: string) => {
            return new URLSearchParams(location.search).get(param);
        };

        // Retrieve the "refer" parameter value
        const referValue = getQueryParam('r');

        // If "refer" parameter exists, save it to localStorage
        if (referValue) {
            localStorage.setItem('r', referValue);
        }
    }, [location]);
    
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${background})`, // Replace 'path_to_your_image.png' with the actual path to your image
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: 'right',
            minHeight: 'calc(100vh - 82px)', // Set the container's minimum height to fill the entire viewport, minus menu
            width: '100vw', // Adjusted width to fill the entire viewport horizontally
            padding: 0,
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Changed to stack children vertically
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Typography variant="h1" gutterBottom textAlign="center">
                    Learn to <b>speak</b> Mandarin
                </Typography>
                {isLoggedIn ? (
                    <Typography variant="h5" sx={{mt: 2}}><a href="/top_referrer">Refer this site</a> to your friends</Typography>
                ) : (
                    <Typography variant="h5" sx={{mt: 2}}><a href="/registration">Register</a> an account</Typography>
                )}
            <Typography variant="h5" sx={{mt: 2}}><a href="/comment">Report a bug</a> or leave a comment</Typography>

            </Box>
        </div>
    );
}
