import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { ChatApi, IChat } from "../api/ChatApi";
import AddIcon from '../asset/icon-add-black.png';
import ChatIcon from '../asset/icon-chat.png';
import ChatListContext from "../context/ChatListContext";
import ErrorMessageContext from "../context/ErrorMessageContext";
import SelectedChatContext from "../context/SelectedChatContext";

export default function ChatMenu() {

    const { selectedChat, setSelectedChat } = React.useContext(SelectedChatContext)
    const { chatList, setChatList } = React.useContext(ChatListContext)
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const history = useHistory();

    React.useEffect(() => {
        ChatApi.getAllOfficeHourChat()
        .then(response => {
            if (response.status < 300) {
                return response.data;
            }
            setErrorMessage(response.statusText);
            return []
        })
        .then(list => setChatList(list.sort((a, b) => b.id - a.id)))
        .catch(err => {
            if (err.response && err.response.status === 401) {
                history.push('/login');
            } else if (err.response && err.response.status == 492) {
                setErrorMessage("This user is read only and cannot delete.");
            } else {
                console.error(err);
                setErrorMessage(err.message);
            }
        })
 }, [])

    const handleListSelect = (chat: IChat) => {
        setSelectedChat(chat)
    }

    const handleAddChat = () => {
        setSelectedChat({ id: -1, hsk_level: -1, first_line: 'AddChat' })
    }

    return (
        <Box>
            <Button
                variant="contained"
                sx={{ width: '100%', p: '12px', mb: 2, mt: 2 }} // Stretch horizontally and add padding
                className="secondaryButton"
                onClick={handleAddChat}
            >
                <img src={AddIcon} alt="Chat" style={{ width: '24px', height: '24px', marginRight: '16px' }} />
                Start a new chat
            </Button>
            <Box alignItems="center" justifyContent="center">
                <Typography variant="h2" color="secondary" sx={{ pl: '32px', pt: 1, pb: 1 }}>
                    Added chats
                </Typography>
            </Box>
            <div style={{ height: 'calc(100vh - 224px)', overflowY: 'auto' }}>
                <Box alignItems="center" justifyContent="center">
                    <List>
                        {chatList.map(chat => {
                            return (
                                <ListItem key={chat['id']}
                                    sx={{ p: 0, my: 2 }}>
                                    <ListItemButton sx={{ p: 0 }} onClick={() => handleListSelect(chat)}>
                                        <ListItemIcon>
                                            <img src={ChatIcon} alt="Chat" style={{ width: '24px', height: '24px' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={chat['first_line'] ? chat['first_line'] : "..."} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            </div>
        </Box>
    )
}