import axios, { AxiosResponse } from 'axios';
import { REST_HOSTNAME } from '../Config';
import { INextDialog } from './NextDialog';
import { ISentenceScore } from './ScoreApi';
import { IVocabularyWord } from './VocabularyWordApi';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface IDialogPromptSentence {
    id: number;
    dialog_prompt_id: number;
    sentence_id: number;
    sentence_text?: string;
    sentence_text_alternate?: string;
    student_response?: string;
    order_index: number;
}

export type DialogPromptTypeUnion = DialogPrompt & DialogPromptImage;

export class DialogPrompt {
    // Add an index signature to allow arbitrary string keys at the cost of type safety.
    [key: string]: any; // Add this index signature
    id: number;
    dialog_id: number;
    order_index: number;
    is_teacher: boolean;
    prompt: string;
    is_note_to_student: boolean;
    class_name: string;
    has_user_input: boolean;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
    ) {
        this.id = id;
        this.class_name = "DialogPrompt";
        this.dialog_id = dialog_id;
        this.order_index = order_index;
        this.is_teacher = is_teacher;
        this.prompt = prompt;
        this.is_note_to_student = is_note_to_student;
        this.has_user_input = false;
    }
}

export class DialogPromptEnterName extends DialogPrompt {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptEnterName"
        this.has_user_input = true;
    }
}

export class DialogPromptImage extends DialogPrompt {
    image_src: string | null;
    image_src_url: string | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        image_src: string | null,
        image_src_url: string | null,
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptImage"
        this.has_user_input = false;
        this.image_src = image_src;
        this.image_src_url = image_src_url;
    }
}

export class DialogPromptIntroduceWord extends DialogPrompt {
    new_word: string;
    pinyin: string[] | null;
    sentence_id: number;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        pinyin: string[] | null,
        sentence_id: number
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptIntroduceWord"
        this.has_user_input = true;
        this.new_word = new_word;
        this.pinyin = pinyin;
        this.sentence_id = sentence_id
    }
}

export class DialogPromptLearnWithCharacter extends DialogPrompt {
    yes_button_text: string;
    no_button_text: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_button_text: string,
        no_button_text: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptLearnWithCharacter"
        this.has_user_input = true;
        this.yes_button_text = yes_button_text
        this.no_button_text = no_button_text
        this.yes_response = yes_response
        this.no_response = no_response
    }
}

export class DialogPromptListenSpeak extends DialogPrompt {
    new_word: string;
    pinyin: string[] | null;
    sentence_id: number;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        pinyin: string[] | null,
        sentence_id: number,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptListenSpeak"
        this.has_user_input = true;
        this.new_word = new_word;
        this.pinyin = pinyin;
        this.sentence_id = sentence_id
        this.yes_response = yes_response
        this.no_response = no_response
    }
}

export class DialogPromptListenSpeakNoGrade extends DialogPrompt {
    new_word: string;
    pinyin: string[] | null;
    sentence_id: number;
    yes_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        pinyin: string[] | null,
        sentence_id: number,
        yes_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptListenSpeakNoGrade"
        this.has_user_input = true;
        this.new_word = new_word;
        this.pinyin = pinyin;
        this.sentence_id = sentence_id
        this.yes_response = yes_response
    }
}

export class DialogPromptQuizWord extends DialogPrompt {
    new_word: string;
    yes_response: string;
    no_response: string;
    wrong_word_1: string | null;
    wrong_word_2: string | null;
    wrong_word_3: string | null;
    wrong_word_4: string | null;
    wrong_word_5: string | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        new_word: string,
        yes_response: string,
        no_response: string,
        wrong_word_1: string | null,
        wrong_word_2: string | null,
        wrong_word_3: string | null,
        wrong_word_4: string | null,
        wrong_word_5: string | null
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptQuizWord"
        this.has_user_input = true;
        this.new_word = new_word;
        this.yes_response = yes_response;
        this.no_response = no_response;
        this.wrong_word_1 = wrong_word_1
        this.wrong_word_2 = wrong_word_2
        this.wrong_word_3 = wrong_word_3
        this.wrong_word_4 = wrong_word_4
        this.wrong_word_5 = wrong_word_5
    }
}

export class DialogPromptSelectAvatar extends DialogPrompt {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptSelectAvatar"
        this.has_user_input = true;
    }
}

export class DialogPromptSelectTeacher extends DialogPrompt {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptSelectTeacher"
        this.has_user_input = true;
    }
}

export class DialogPromptStartClass extends DialogPrompt {
    yes_button_text: string;
    no_button_text: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_button_text: string,
        no_button_text: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptStartClass"
        this.has_user_input = true;
        this.yes_button_text = yes_button_text
        this.no_button_text = no_button_text
        this.yes_response = yes_response
        this.no_response = no_response
    }
}

export class DialogPromptStartNextLesson extends DialogPrompt {
    yes_button_text: string;
    no_button_text: string;
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_button_text: string,
        no_button_text: string,
        yes_response: string,
        no_response: string
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptStartNextLesson"
        this.has_user_input = true;
        this.yes_button_text = yes_button_text
        this.no_button_text = no_button_text
        this.yes_response = yes_response
        this.no_response = no_response
    }
}


export class DialogPromptMatchWordPicture extends DialogPrompt {
    vocabulary_word_list: IVocabularyWord[];
    yes_response: string;
    no_response: string;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        vocabulary_word_list: IVocabularyWord[]
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptMatchWordPicture"
        this.has_user_input = true;
        this.yes_response = yes_response
        this.no_response = no_response
        this.vocabulary_word_list = vocabulary_word_list
    }
}


export class DialogPromptQuizQuestion extends DialogPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string;
    correct_vocabulary_word_id: number;
    correct_vocabulary_word: IVocabularyWord;
    incorrect_vocabulary_word_list: IVocabularyWord[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string,
        correct_vocabulary_word_id: number,
        correct_vocabulary_word: IVocabularyWord,
        incorrect_vocabulary_word_list: IVocabularyWord[]
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student)
        this.class_name = "DialogPromptQuizQuestion"
        this.has_user_input = true
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.correct_vocabulary_word_id = correct_vocabulary_word_id
        this.correct_vocabulary_word = correct_vocabulary_word
        this.incorrect_vocabulary_word_list = incorrect_vocabulary_word_list
    }
}


export class DialogPromptQuizQuestionAudio extends DialogPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string;
    correct_vocabulary_word_id: number;
    correct_vocabulary_word: IVocabularyWord;
    incorrect_vocabulary_word_list: IVocabularyWord[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string,
        correct_vocabulary_word_id: number,
        correct_vocabulary_word: IVocabularyWord,
        incorrect_vocabulary_word_list: IVocabularyWord[]
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student)
        this.class_name = "DialogPromptQuizQuestionAudio"
        this.has_user_input = true
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.correct_vocabulary_word_id = correct_vocabulary_word_id
        this.correct_vocabulary_word = correct_vocabulary_word
        this.incorrect_vocabulary_word_list = incorrect_vocabulary_word_list
    }
}


export class DialogPromptTypeSpeak extends DialogPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string | null;
    teacher_chinese_prompt_sentence_id: number | null;
    student_response_prompt: string | null;
    dialog_prompt_sentence_list: IDialogPromptSentence[];
    teacher_ask_student_to_speak_prompt: string | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string | null,
        teacher_chinese_prompt_sentence_id: number | null,
        student_response_prompt: string | null,
        dialog_prompt_sentence_list: IDialogPromptSentence[],
        teacher_ask_student_to_speak_prompt: string | null,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptTypeSpeak"
        this.has_user_input = true;
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.teacher_chinese_prompt_sentence_id = teacher_chinese_prompt_sentence_id
        this.student_response_prompt = student_response_prompt
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
        this.teacher_ask_student_to_speak_prompt = teacher_ask_student_to_speak_prompt
    }
}


export class DialogPromptConversationListen extends DialogPrompt {
    dialog_prompt_sentence_list: IDialogPromptSentence[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        dialog_prompt_sentence_list: IDialogPromptSentence[],

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptConversationListen"
        this.has_user_input = true;
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
    }
}

export class DialogPromptConversationListenWithoutCharacter extends DialogPrompt {
    dialog_prompt_conversation_listen_id: number;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        dialog_prompt_conversation_listen_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptConversationListenWithoutCharacter"
        this.dialog_prompt_conversation_listen_id = dialog_prompt_conversation_listen_id
        this.has_user_input = false;
    }
}

export class DialogPromptConversationRelated extends DialogPrompt {
    yes_response: string;
    no_response: string;
    dialog_prompt_conversation_listen_id: number;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_listen_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "Abstract"
        this.yes_response = yes_response;
        this.no_response = no_response;
        this.dialog_prompt_conversation_listen_id = dialog_prompt_conversation_listen_id
    }
}

export class DialogPromptConversationListenSpeak extends DialogPromptConversationRelated {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_listen_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student,
            yes_response, no_response, dialog_prompt_conversation_listen_id);
        this.class_name = "DialogPromptConversationListenSpeak"
        this.has_user_input = true;
    }
}


export class DialogPromptConversationReadSpeak extends DialogPromptConversationRelated {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_listen_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student,
            yes_response, no_response, dialog_prompt_conversation_listen_id);
        this.class_name = "DialogPromptConversationReadSpeak"
        this.has_user_input = true;
    }
}


export class DialogPromptConversationReadWithBlankSpeak extends DialogPromptConversationRelated {
    dialog_prompt_sentence_list: IDialogPromptSentence[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_listen_id: number,
        dialog_prompt_sentence_list: IDialogPromptSentence[],

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student,
            yes_response, no_response, dialog_prompt_conversation_listen_id);
        this.class_name = "DialogPromptConversationReadWithBlankSpeak"
        this.has_user_input = true;
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
    }
}


export class DialogPromptConversationSpeakOnly extends DialogPromptConversationRelated {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        dialog_prompt_conversation_listen_id: number,

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student,
            yes_response, no_response, dialog_prompt_conversation_listen_id);
        this.class_name = "DialogPromptConversationSpeakOnly"
        this.has_user_input = true;
    }
}


export class DialogPromptSelectLengthTime extends DialogPrompt {

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptSelectLengthTime"
        this.has_user_input = true;
    }
}


export class DialogPromptSelectNextDialog extends DialogPrompt {
    next_dialog_list: INextDialog[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        next_dialog_list: INextDialog[],

    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptSelectNextDialog"
        this.has_user_input = true;
        this.next_dialog_list = next_dialog_list
    }
}


export class DialogPromptSpeakNoGrade extends DialogPrompt {
    yes_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string | null;
    teacher_chinese_prompt_sentence_id: number | null;
    student_response_prompt: string | null;

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string | null,
        teacher_chinese_prompt_sentence_id: number | null,
        student_response_prompt: string | null,
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptSpeakNoGrade"
        this.has_user_input = true;
        this.yes_response = yes_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.teacher_chinese_prompt_sentence_id = teacher_chinese_prompt_sentence_id
        this.student_response_prompt = student_response_prompt
    }
}


export class DialogPromptSpeakWithGrade extends DialogPrompt {
    yes_response: string;
    no_response: string;
    image_src: string | null;
    image_src_url: string | null;
    teacher_chinese_prompt: string | null;
    teacher_chinese_prompt_sentence_id: number | null;
    student_response_prompt: string | null;
    teacher_hint_prompt: string | null;
    dialog_prompt_sentence_list: IDialogPromptSentence[];

    constructor(
        id: number,
        dialog_id: number,
        order_index: number,
        is_teacher: boolean,
        prompt: string,
        is_note_to_student: boolean,
        yes_response: string,
        no_response: string,
        image_src: string | null,
        image_src_url: string | null,
        teacher_chinese_prompt: string | null,
        teacher_chinese_prompt_sentence_id: number | null,
        student_response_prompt: string | null,
        teacher_hint_prompt: string | null,
        dialog_prompt_sentence_list: IDialogPromptSentence[],
    ) {
        super(id, dialog_id, order_index, is_teacher, prompt, is_note_to_student);
        this.class_name = "DialogPromptSpeakWithGrade"
        this.has_user_input = true;
        this.yes_response = yes_response
        this.no_response = no_response
        this.image_src = image_src;
        this.image_src_url = image_src_url;
        this.teacher_chinese_prompt = teacher_chinese_prompt
        this.teacher_chinese_prompt_sentence_id = teacher_chinese_prompt_sentence_id
        this.student_response_prompt = student_response_prompt
        this.teacher_hint_prompt = teacher_hint_prompt
        this.dialog_prompt_sentence_list = dialog_prompt_sentence_list
    }
}

export const DialogPromptApi = {
    getAll: function (dialogId?: number, className?: string): Promise<AxiosResponse<DialogPrompt[]>> {
        return axiosInstance.request<DialogPrompt[]>({
            method: "GET",
            url: `/dialog_prompt${dialogId ? `?dialog_id=${dialogId}` : ''}${className ? `?class_name=${className}` : ''}`
        });
    },
    getById: function (dialogPromptId: number) {
        return axiosInstance.request<DialogPrompt>({
            method: "GET",
            url: `/dialog_prompt/${dialogPromptId}`
        });
    },
    create: function (dialogPrompt: DialogPrompt) {
        return axiosInstance.request<DialogPrompt>({
            method: "POST",
            url: `/dialog_prompt`,
            data: dialogPrompt
        });
    },
    update: function (dialogPrompt: DialogPrompt): Promise<any[]> {
        const promiseList = [];
        promiseList.unshift(
            axiosInstance.request<string>({
                method: "PUT",
                url: `/dialog_prompt`,
                data: dialogPrompt
            })
        );
        return Promise.all(promiseList);
    },
    delete: function (dialogPromptId: number) {
        return axiosInstance.request<string>({
            method: "DELETE",
            url: `/dialog_prompt/${dialogPromptId}`,
        });
    },
    getDialogPromptTypeSpeakMatch: function (dialogPromptId: number, word: string) {
        return axiosInstance.request<string>({
            method: "GET",
            url: `/typed_response/dialog_prompt_type_speak_match/${dialogPromptId}/${word}`
        });
    },
    getDialogPromptSpeakWithGradeMatch: function (dialogPromptId: number, audio: any) {
        return axiosInstance.request<ISentenceScore>({
            method: "POST",
            url: `/spoken_response/dialog_prompt_speak_with_grade_match/${dialogPromptId}`,
            data: audio
        });
    }
}


export function DialogPromptFactory(className: string,
    id: number,
    dialogId: number,
    orderIndex: number,
    isTeacher: boolean,
    prompt: string,
    isNoteToStudent: boolean,
    imageSource?: string | null,
    imageSourceUrl?: string | null,
    newWord?: string | null,
    yesButtonText?: string | null,
    noButtonText?: string | null,
    yesResponse?: string | null,
    noResponse?: string | null,
    sentenceId?: number | null,
): DialogPrompt {
    if (className == "DialogPrompt") {
        const dialogPrompt = new DialogPrompt(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent
        );
        return dialogPrompt
    } else if (className == "DialogPromptEnterName") {
        const dialogPrompt = new DialogPromptEnterName(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent
        );
        return dialogPrompt
    } else if (className == "DialogPromptImage") {
        const dialogPrompt = new DialogPromptImage(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            imageSource ? imageSource : "broken",
            imageSourceUrl ? imageSourceUrl : "broken",
        );
        return dialogPrompt
    } else if (className == "DialogPromptIntroduceWord") {
        const dialogPrompt = new DialogPromptIntroduceWord(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            null,
            sentenceId ? sentenceId : -1
        );
        return dialogPrompt
    } else if (className == "DialogPromptLearnWithCharacter") {
        const dialogPrompt = new DialogPromptLearnWithCharacter(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesButtonText ? yesButtonText : "",
            noButtonText ? noButtonText : "",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptListenSpeak") {
        const dialogPrompt = new DialogPromptListenSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            null,
            sentenceId ? sentenceId : -1,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptListenSpeakNoGrade") {
        const dialogPrompt = new DialogPromptListenSpeakNoGrade(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            null,
            sentenceId ? sentenceId : -1,
            yesResponse ? yesResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptQuizWord") {
        const dialogPrompt = new DialogPromptQuizWord(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord ? newWord : "broken",
            yesResponse ? yesResponse : "broken",
            noResponse ? noResponse : "broken",
            null, null, null, null, null
        );
        return dialogPrompt
    } else if (className == "DialogPromptSelectAvatar") {
        const dialogPrompt = new DialogPromptSelectAvatar(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent
        );
        return dialogPrompt
    } else if (className == "DialogPromptSelectTeacher") {
        const dialogPrompt = new DialogPromptSelectTeacher(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent
        );
        return dialogPrompt
    } else if (className == "DialogPromptStartClass") {
        const dialogPrompt = new DialogPromptStartClass(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesButtonText ? yesButtonText : "",
            noButtonText ? noButtonText : "",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptStartNextLesson") {
        const dialogPrompt = new DialogPromptStartNextLesson(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesButtonText ? yesButtonText : "",
            noButtonText ? noButtonText : "",
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : ""
        );
        return dialogPrompt
    } else if (className == "DialogPromptMatchWordPicture") {
        const dialogPrompt = new DialogPromptMatchWordPicture(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            []
        );
        return dialogPrompt
    } else if (className == "DialogPromptTypeSpeak") {
        const dialogPrompt = new DialogPromptTypeSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            imageSource ? imageSource : "",
            imageSourceUrl ? imageSourceUrl : "broken",
            "broken", null, "broken",
            [],
            "broken"
        );
        return dialogPrompt
    } else if (className == "DialogPromptQuizQuestion") {
        const dialogPrompt = new DialogPromptQuizQuestion(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "", "",
            "",
            0,
            {
                id: 0,
                word: '',
                image: '',
                image_url: '',
                audio_sentence: '',
                audio_sentence_id: 0,
                dialog_prompt_id: 0,
                teacher_english_prompt: '',
                teacher_chinese_prompt: '',
                teacher_chinese_prompt_sentence_id: 0,
                student_response_prompt: '<Answer/>',
                student_response_prompt_sentence_id: 0
            },
            []
        );
        return dialogPrompt
    } else if (className == "DialogPromptQuizQuestionAudio") {
        const dialogPrompt = new DialogPromptQuizQuestionAudio(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            "", "",
            "",
            0,
            {
                id: 0,
                word: '',
                image: '',
                image_url: '',
                audio_sentence: '',
                audio_sentence_id: 0,
                dialog_prompt_id: 0,
                teacher_english_prompt: '',
                teacher_chinese_prompt: '',
                teacher_chinese_prompt_sentence_id: 0,
                student_response_prompt: '<Answer/>',
                student_response_prompt_sentence_id: 0
            },
            []
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationListen") {
        const dialogPrompt = new DialogPromptConversationListen(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            [],
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationListenSpeak") {
        const dialogPrompt = new DialogPromptConversationListenSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationListenWithoutCharacter") {
        const dialogPrompt = new DialogPromptConversationListenWithoutCharacter(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationReadSpeak") {
        const dialogPrompt = new DialogPromptConversationReadSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationReadWithBlankSpeak") {
        const dialogPrompt = new DialogPromptConversationReadWithBlankSpeak(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            -1,
            []
        );
        return dialogPrompt
    } else if (className == "DialogPromptConversationSpeakOnly") {
        const dialogPrompt = new DialogPromptConversationSpeakOnly(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            -1,
        );
        return dialogPrompt
    } else if (className == "DialogPromptSelectLengthTime") {
        const dialogPrompt = new DialogPromptSelectLengthTime(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent
        );
        return dialogPrompt
    } else if (className == "DialogPromptSelectNextDialog") {
        const dialogPrompt = new DialogPromptSelectNextDialog(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            [],
        );
        return dialogPrompt
    } else if (className == "DialogPromptSpeakNoGrade") {
        const dialogPrompt = new DialogPromptSpeakNoGrade(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            imageSource ? imageSource : "",
            imageSourceUrl ? imageSourceUrl : "broken",
            "broken", null, "broken"
        );
        return dialogPrompt
    } else if (className == "DialogPromptSpeakWithGrade") {
        const dialogPrompt = new DialogPromptSpeakWithGrade(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse ? yesResponse : "",
            noResponse ? noResponse : "",
            imageSource ? imageSource : "",
            imageSourceUrl ? imageSourceUrl : "broken",
            "broken", null, "broken", "broken",
            [],
        );
        return dialogPrompt
    }
    throw new Error("Unknown dialog prompt class " + className);
}
