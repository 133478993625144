import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KaiTi from './KaiTi.ttf';

export const KaiTiFont = {
  fontFamily: 'KaiTi',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('KaiTi'),
      url(${KaiTi}) format('truetype')
    `,
};

export function useKaiTiFontStyles(color: string | null = null, fontSize: string | null = null) {
  return makeStyles((theme: Theme) => {
    return ({
      customFont: {
        fontFamily: 'KaiTi, Roboto, Helvetica, Arial, sans-serif',
        ...(color !== null && { color: color }),
        ...(fontSize !== null && { fontSize: fontSize }),
      },
    });
  })();
}