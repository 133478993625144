import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CourseApi, ICourse } from '../../api/CourseApi';
import { DialogApi, IDialog } from '../../api/DialogApi';
import { DialogPlanApi, IDialogPlan, IGeneratedDialogPlanResponse } from '../../api/DialogPlanApi';
import { DialogPlanLlmPrompt, DialogPlanLlmPromptApi } from '../../api/DialogPlanLlmPromptApi';
import { DialogPromptApi } from '../../api/DialogPromptApi';
import { ILesson, LessonApi } from '../../api/LessonApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import DialogPlanLlmPromptForm from './DialogPlanLlmPromptForm';
import DialogPlanLlmPromptGenerated from './DialogPlanLlmPromptGenerated';

interface DialogPlanFormProps {
    dialogPlanId: number;
    onSubmit: (updatedDialogPlan: IDialogPlan) => void;
    onDelete: (dialogPlanId: number) => void;
}

const DialogPlanForm: React.FC<DialogPlanFormProps> = ({ dialogPlanId, onSubmit, onDelete }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [id, setId] = React.useState(-1);
    const [name, setName] = React.useState('');
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [hskLevel, setHskLevel] = React.useState(1);

    const [variableList, setVariableList] = React.useState<string[]>([])
    const [unsupportedVariableList, setUnsupportedVariableList] = React.useState<string[]>([])

    const [vocabularyWord1, setVocabularyWord1] = React.useState('');
    const [vocabularyWord2, setVocabularyWord2] = React.useState('');
    const [vocabularyWord3, setVocabularyWord3] = React.useState('');
    const [vocabularyWord4, setVocabularyWord4] = React.useState('');
    const [vocabularyWord5, setVocabularyWord5] = React.useState('');
    const [generatedDialogTitle, setGeneratedDialogTitle] = React.useState('');
    const [generatedDialogPlanResponse, setGeneratedDialogPlanResponse] = React.useState<IGeneratedDialogPlanResponse | null>(null)
    const [isGenerating, setIsGenerating] = React.useState(false);
    const hasScrolledToGeneratedPrompt = React.useRef(false); // Use ref to track if scrolling has occurred

    const [isRegenerating, setIsRegenerating] = React.useState<{ [key: number]: boolean }>({});
    const [isInserting, setIsInserting] = React.useState(false);

    const [courseList, setCourseList] = React.useState<ICourse[]>([]); // State to store course options
    const [lessonDictionary, setLessonDictionary] = React.useState<{ [key: number]: ILesson[]; }>({});
    const [dialogDictionary, setDialogDictionary] = React.useState<{ [key: number]: IDialog[]; }>([]);
    const [insertLessonAndDialogId, setInsertLessonAndDialogId] = React.useState<string>("");


    React.useEffect(() => {
        const fetchCourseList = async () => {
            try {
                const response = await CourseApi.getAll();
                setCourseList(response.data);
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            }
        };
        const fetchLessonDictionary = async () => {
            try {
                const response = await LessonApi.getAll();
                const lessonList = response.data

                const lessonDictionary = lessonList.reduce((accumulator, currentLesson) => {
                    const courseId = currentLesson.course_id;

                    // If course_id exists in the accumulator, push the lesson into the array
                    if (accumulator[courseId]) {
                        accumulator[courseId].push(currentLesson);
                    } else {
                        // Otherwise, initialize an array with the current lesson
                        accumulator[courseId] = [currentLesson];
                    }

                    return accumulator;
                }, {} as { [key: number]: typeof lessonList[number][] }); // The value is an array of lessons

                setLessonDictionary(lessonDictionary);

            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            }
        };
        const fetchDialogDictionary = async () => {
            try {
                const response = await DialogApi.getAll();
                const dialogList = response.data

                const dialogDictionary = dialogList.reduce((accumulator, currentDialog) => {
                    const lessonId = currentDialog.lesson_id;

                    // If course_id exists in the accumulator, push the dialog into the array
                    if (accumulator[lessonId]) {
                        accumulator[lessonId].push(currentDialog);
                    } else {
                        // Otherwise, initialize an array with the current dialog
                        accumulator[lessonId] = [currentDialog];
                    }

                    return accumulator;
                }, {} as { [key: number]: typeof dialogList[number][] }); // The value is an array of lessons

                setDialogDictionary(dialogDictionary);

            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            }
        };
        fetchCourseList();
        fetchLessonDictionary();
        fetchDialogDictionary();
    }, []);

    React.useEffect(() => {
        DialogPlanApi.getById(dialogPlanId)
            .then((response) => {
                const dialogPlan = response.data;
                setId(dialogPlan.id);
                setName(dialogPlan.name);
                setDialogTitle(dialogPlan.dialog_title);
                setGeneratedDialogPlanResponse(null);
                setVariableList(dialogPlan.variable_list ? dialogPlan.variable_list : [])
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }, [dialogPlanId]);

    React.useEffect(() => {
        const supportedVariableList = ['<VocabularyWord1/>', '<VocabularyWord2/>', '<VocabularyWord3/>', '<VocabularyWord4/>', '<VocabularyWord5/>',
            '<HskLevel/>', '<StudentName/>', '<TeacherName/>', '<Microphone/>', '<Speaker/>', '<Info/>', '<Answer/>',
            '<IncorrectVocabularyWord/>']
        const unsupportedVariableList = variableList.filter(
            (variable) => !supportedVariableList.includes(variable) // Check if the variable is not in supportedVariables
        );
        setUnsupportedVariableList(unsupportedVariableList)
    }, [variableList])

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(Number(e.target.value));
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleDialogTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDialogTitle(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const updateDialogPlan: IDialogPlan = {
            id: id,
            name: name,
            dialog_title: dialogTitle,
        };
        onSubmit(updateDialogPlan);
    };


    const handleDeleteConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setShowConfirmation(false);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmation(false);
        onDelete(dialogPlanId);
    };

    const handleVocabularyWord1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVocabularyWord1(e.target.value);
    };

    const handleVocabularyWord2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVocabularyWord2(e.target.value);
    };

    const handleVocabularyWord3Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVocabularyWord3(e.target.value);
    };

    const handleVocabularyWord4Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVocabularyWord4(e.target.value);
    };

    const handleVocabularyWord5Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVocabularyWord5(e.target.value);
    };

    const handleGenerateDialogPlan = () => {
        setIsGenerating(true)
        DialogPlanApi.generate(hskLevel, dialogPlanId, true, vocabularyWord1, vocabularyWord2, vocabularyWord3, vocabularyWord4, vocabularyWord5)
            .then((response) => {
                const generatedDialogPlanResponse = response.data;
                setGeneratedDialogPlanResponse(generatedDialogPlanResponse)
                setGeneratedDialogTitle(generatedDialogPlanResponse.dialog.title)
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
            .finally(() => {
                setIsGenerating(false)
            })
    }

    const handleGenerateAllDialogPlanLlmPromptInParallel = () => {
        setIsGenerating(true)
        // first generate dialog plan, without prompts, to generate things like the title 
        DialogPlanApi.generate(hskLevel, dialogPlanId, false, vocabularyWord1, vocabularyWord2, vocabularyWord3, vocabularyWord4, vocabularyWord5)
            .then((response) => {
                const generatedDialogPlanResponse = response.data;
                generatedDialogPlanResponse.dialog_plan_llm_prompt_list = []
                generatedDialogPlanResponse.dialog_prompt_list = []
                setGeneratedDialogPlanResponse(generatedDialogPlanResponse)
                setGeneratedDialogTitle(generatedDialogPlanResponse.dialog.title)

                // setIsRegenerating(
                //     new Array(generatedDialogPlanResponse.dialog_plan_llm_prompt_list.length).fill(false)
                // );
                const initialIsRegenerating = generatedDialogPlanResponse.dialog_plan_llm_prompt_list.reduce((acc, prompt) => {
                    acc[prompt.id] = false; // Initialize each prompt's isGenerating to false
                    return acc;
                }, {} as { [key: number]: boolean });

                setIsRegenerating(initialIsRegenerating);

                DialogPlanLlmPromptApi.getAll(dialogPlanId = dialogPlanId)
                    .then((response) => {
                        const dialogPlanLlmPromptList = response.data;

                        // Create an array of promises using `map` to call `generate()` for each prompt
                        const generatePromises = dialogPlanLlmPromptList.map((prompt) => {

                            return DialogPlanLlmPromptApi.generateById(hskLevel, prompt.id, vocabularyWord1, vocabularyWord2, vocabularyWord3, vocabularyWord4, vocabularyWord5)
                                .then((response) => {
                                    const generatedDialogPlanLlmPromptResponse = response.data

                                    setGeneratedDialogPlanResponse((prevState) => {
                                        if (!prevState) return null; // Ensure prevState exists

                                        const updatedDialogPlanLlmPromptList = [
                                            ...prevState.dialog_plan_llm_prompt_list, // Use prevState to maintain the current state
                                            generatedDialogPlanLlmPromptResponse.dialog_plan_llm_prompt
                                        ];
                                        updatedDialogPlanLlmPromptList.sort((a, b) => a.order_index - b.order_index);

                                        const updatedDialogPromptList = [
                                            ...prevState.dialog_prompt_list, // Use prevState to maintain the current state
                                            generatedDialogPlanLlmPromptResponse.dialog_prompt
                                        ];
                                        updatedDialogPromptList.sort((a, b) => a.order_index - b.order_index);

                                        // Update the state by spreading prevState and updating the arrays
                                        return {
                                            ...prevState,
                                            dialog_plan_llm_prompt_list: updatedDialogPlanLlmPromptList,
                                            dialog_prompt_list: updatedDialogPromptList,
                                        };
                                    });
                                })
                                .catch(err => {
                                    console.error(`Failed to generate for prompt with ID ${prompt.id}:`, err);
                                    setErrorMessage(err.message);
                                });
                        });

                        // Execute all the `generate()` calls concurrently
                        return Promise.all(generatePromises)
                            .finally(() => { setIsGenerating(false) });
                    })
                    .catch(err => {
                        setIsGenerating(false);
                        console.error(err);
                        setErrorMessage(err.message);
                    })
            })
            .catch(err => {
                setIsGenerating(false);
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.message);
                }
            })
    }

    React.useEffect(() => {
        // Check if scrolling hasn't happened yet and list has at least one element
        if (
            generatedDialogPlanResponse &&
            generatedDialogPlanResponse.dialog_plan_llm_prompt_list.length === 1 &&
            !hasScrolledToGeneratedPrompt.current
        ) {
            const element = document.getElementById('form-0'); // Scroll to the first element
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                hasScrolledToGeneratedPrompt.current = true; // Mark scrolling as done
            }
        }
    }, [generatedDialogPlanResponse]);

    const handleLlmPromptChange = (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt) => {
        setGeneratedDialogPlanResponse((prevState) => {
            if (!prevState) return prevState;

            // Map over the list and replace the entire object where the ID matches
            const updatedDialogPlanLlmPromptList = prevState.dialog_plan_llm_prompt_list.map((prompt) =>
                prompt.id === updatedDialogPlanLlmPrompt.id
                    ? updatedDialogPlanLlmPrompt // Replace the entire object
                    : prompt // Keep the other prompts unchanged
            );

            // Return the updated state with the modified prompt list
            return {
                ...prevState,
                dialog_plan_llm_prompt_list: updatedDialogPlanLlmPromptList,
            };
        });
    }

    const handleRegenerateDialogPlanLlmPrompt = (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => {
        // Set isGenerating true for this specific dialogPlanLlmPrompt.id
        setIsRegenerating((prevState) => ({
            ...prevState,
            [dialogPlanLlmPrompt.id]: true
        }));

        if (!generatedDialogPlanResponse) throw Error("generatedDialogPlanResponse is still empty. please generate")

        // Find the index of the dialogPlanLlmPrompt in dialog_plan_llm_prompt_list
        const promptIndex = generatedDialogPlanResponse.dialog_plan_llm_prompt_list.findIndex(
            (prompt) => prompt.id === dialogPlanLlmPrompt.id
        );

        // If the index is found, return the corresponding dialog_prompt
        if (promptIndex < 0) {
            throw Error(`Unable to find dialogPlanLlmPrompt id=${dialogPlanLlmPrompt.id} in generatedDialogPlanResponse`)
        }

        const dialogPrompt = generatedDialogPlanResponse.dialog_prompt_list[promptIndex];

        DialogPlanLlmPromptApi.generate(hskLevel, dialogPlanLlmPrompt, dialogPrompt, fieldName, vocabularyWord1, vocabularyWord2, vocabularyWord3, vocabularyWord4, vocabularyWord5)
            .then((response) => {
                const generatedDialogPlanLlmPromptResponse = response.data

                setGeneratedDialogPlanResponse((prevState) => {
                    if (!prevState) return null; // Ensure prevState exists

                    // Find the index of the dialogPlanLlmPrompt that should be updated
                    const promptIndex = prevState.dialog_plan_llm_prompt_list.findIndex(
                        (prompt) => prompt.id === generatedDialogPlanLlmPromptResponse.dialog_plan_llm_prompt.id
                    );

                    if (promptIndex === -1) {
                        // If no matching prompt is found, return the previous state
                        return prevState;
                    }

                    // Create a copy of the dialog_plan_llm_prompt_list and update the item at the found index
                    const updatedDialogPlanLlmPromptList = [...prevState.dialog_plan_llm_prompt_list];

                    if (fieldName) {
                        // Update only the specified field, Keep other fields unchanged
                        updatedDialogPlanLlmPromptList[promptIndex] = {
                            ...updatedDialogPlanLlmPromptList[promptIndex],
                            // @ts-ignore
                            [fieldName]: generatedDialogPlanLlmPromptResponse.dialog_plan_llm_prompt[fieldName],
                        };
                    } else {
                        updatedDialogPlanLlmPromptList[promptIndex] = generatedDialogPlanLlmPromptResponse.dialog_plan_llm_prompt;
                    }

                    // Sort the updated list by order_index
                    updatedDialogPlanLlmPromptList.sort((a, b) => a.order_index - b.order_index);

                    // Create a copy of the dialog_prompt_list and update the item at the same index
                    const updatedDialogPromptList = [...prevState.dialog_prompt_list];

                    if (fieldName) {
                        // Update only the specified field, Keep other fields unchanged
                        updatedDialogPromptList[promptIndex] = {
                            ...updatedDialogPromptList[promptIndex],
                            // @ts-ignore
                            [fieldName]: generatedDialogPlanLlmPromptResponse.dialog_prompt[fieldName],
                        };
                    } else {
                        updatedDialogPromptList[promptIndex] = generatedDialogPlanLlmPromptResponse.dialog_prompt
                    }

                    // Sort the updated dialog prompt list by order_index (if needed)
                    updatedDialogPromptList.sort((a, b) => a.order_index - b.order_index);

                    // Update the state by spreading prevState and updating the arrays
                    return {
                        ...prevState,
                        dialog_plan_llm_prompt_list: updatedDialogPlanLlmPromptList,
                        dialog_prompt_list: updatedDialogPromptList,
                    };
                });
            })
            .catch(err => {
                console.error(`Failed to generate for prompt with ID ${dialogPlanLlmPrompt.id}:`, err);
                setErrorMessage(err.message);
            })
            .finally(() => {
                // Set isGenerating true for this specific dialogPlanLlmPrompt.id
                setIsRegenerating((prevState) => ({
                    ...prevState,
                    [dialogPlanLlmPrompt.id]: false
                }));
            })
    }

    // Handle the `onChange` event to update the temporary value
    const handleGeneratedDialogTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGeneratedDialogTitle(e.target.value); // Keep track of the text as the user types
    };
    const handleGeneratedDialogTitleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const newTitle = e.target.value; // Get the new value from the input

        setGeneratedDialogPlanResponse(prevState => {
            if (prevState) {
                return {
                    ...prevState,
                    dialog: {
                        ...prevState.dialog,
                        dialogTitle: newTitle // Update the specific field with the new title
                    }
                };
            } else {
                return prevState;
            }
        });
    };

    const [isInsertDialogPromptValid, setIsInsertDialogPromptValid] = React.useState(true); // Track validation state
    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        setInsertLessonAndDialogId(e.target.value);
        setIsInsertDialogPromptValid(true); // Reset the validation state when user selects an option
    };

    const handleInsertDialogPrompt = () => {
        if (!insertLessonAndDialogId) {
            setIsInsertDialogPromptValid(false); // Mark as invalid if nothing is selected
            return;
        }
        if (insertLessonAndDialogId && generatedDialogPlanResponse) {
            setIsInserting(true)
            // Split the string using '-' as the separator
            const [lessonIdString, dialogIdString] = insertLessonAndDialogId.split('-');
            const insertLessonId = lessonIdString ? parseInt(lessonIdString, 10) : null;
            const insertDialogId = dialogIdString ? parseInt(dialogIdString, 10) : null;

            if (!insertLessonId) {
                throw Error("invalid lesson dialog id string '" + insertLessonAndDialogId + "'")
            }
            let orderIndex: number | null = null;
            if (!(insertLessonId in dialogDictionary) || dialogDictionary[insertLessonId].length == 0) {
                orderIndex = 1;
            } else {
                const dialogList = dialogDictionary[insertLessonId];

                if (insertDialogId == null) {
                    orderIndex = dialogList[0].order_index - 1;
                } else if (dialogList[0]) {
                    let beforeOrderIndex: number | null = null;
                    let afterOrderIndex: number | null = null;
                    for (let i = 0; i < dialogList.length; i++) {
                        beforeOrderIndex = dialogList[i].order_index;
                        if (i + 1 < dialogList.length) {
                            afterOrderIndex = dialogList[i + 1].order_index;
                        } else {
                            afterOrderIndex = null;
                        }
                        if (dialogList[i].id === insertDialogId) {
                            break;
                        }
                    }

                    if (afterOrderIndex == null) {
                        orderIndex = beforeOrderIndex;
                    } else if (beforeOrderIndex != null) {
                        orderIndex = (beforeOrderIndex + afterOrderIndex) / 2
                    } else {
                        throw Error("handleInsertDialogPrompt() has a bug of some kind")
                    }
                }
            }
            const newDialog: IDialog = {
                id: 0,
                lesson_id: insertLessonId,
                order_index: orderIndex ? orderIndex : 0,
                title: generatedDialogTitle,
            }
            DialogApi.create(newDialog)
                .then(response => {
                    if (response.status < 300) {
                        return response.data
                    }
                    setErrorMessage(response.statusText);
                    return null
                })
                .then(newDialogWithId => {
                    if (newDialogWithId) {
                        const promises = [];
                        for (let i = 0; i < generatedDialogPlanResponse.dialog_prompt_list.length; i++) {
                            const dialogPrompt = generatedDialogPlanResponse.dialog_prompt_list[i]
                            dialogPrompt.dialog_id = newDialogWithId.id;
                            const promise = DialogPromptApi.create(dialogPrompt)
                                .then(response => {
                                    if (response.status < 300) {
                                        return response.data
                                    }
                                    setErrorMessage(response.statusText);
                                    return null
                                })
                                .then(newDialogPromptWithId => {
                                    // TODO?
                                    setGeneratedDialogPlanResponse(null)
                                })
                                .catch(err => {
                                    if (err.response && err.response.status === 401) {
                                        history.push('/login');
                                    } else {
                                        console.error(err);
                                        setErrorMessage(err.message);
                                    }
                                })
                            promises.push(promise);
                        }
                        // Wait for all promises to settle (either resolved or rejected)
                        Promise.allSettled(promises)
                            .then(() => {
                                // After all of the API calls have completed, set isInserting to false
                                setIsInserting(false);
                            });
                    } else {
                        setIsInserting(false);
                    }
                })
                .catch(err => {
                    setIsInserting(false)
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.message);
                    }
                })
        }
    }

    return (<>
        <form onSubmit={handleSubmit}>
            <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
                <Typography variant="h4">
                    Edit Dialog Plan
                </Typography>
                <input type="hidden" value={id} onChange={handleIdChange} />
                <TextField
                    label="Name"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
                <div>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;VocabularyWord1/&gt; will be replaced with Vocabulary Word Input when generating. <br />
                        Also &lt;VocabularyWord2/&gt;, &lt;VocabularyWord3/&gt;, &lt;VocabularyWord4/&gt;, &lt;VocabularyWord5/&gt;
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;HskLevel/&gt; with the number provided, the number only 1-6
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;StudentName/&gt; will be replaced with the student's name
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;TeacherName/&gt; will be replaced with the teacher's name
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Microphone/&gt; will be replaced with the microphone icon for recording
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Speaker/&gt; will be replaced with the speaker icon for listening
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Info/&gt; will be replaced with the info icon for more details
                    </Typography>
                </div>
                <TextField
                    label="Dialog Title"
                    value={dialogTitle}
                    onChange={handleDialogTitleChange}
                    required
                />
                <Button type="submit" variant="contained">
                    Save
                </Button>
                <Button variant="contained" color="error" onClick={handleDeleteConfirmation}>
                    Delete
                </Button>
                <Dialog open={showConfirmation} onClose={handleDeleteCancel}>
                    <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Delete Course</Typography></DialogTitle>
                    <DialogContent>
                        <Typography sx={{ color: '#8D8D8D' }}>Are you sure you want to delete this course?</Typography>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                        <Button onClick={handleDeleteCancel} sx={{ color: '#787878' }}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm} sx={{ color: '#787878' }} autoFocus>Delete</Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </form>
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
            <Typography variant="h4">
                Generate Dialog Plan
            </Typography>
            {/* Dropdown for HSK Level */}
            <Select
                labelId="hsk-level-label"
                id="hsk-level-select"
                value={hskLevel}
                label="HSK Level"
                onChange={(event) => setHskLevel(Number(event.target.value))}
            >
                {[1, 2, 3, 4, 5, 6].map((level) => (
                    <MenuItem key={level} value={level}>
                        HSK Level {level}
                    </MenuItem>
                ))}
            </Select>
            {unsupportedVariableList.length > 0 && (
                <div>
                    <Typography variant="body1" sx={{ color: 'red' }}>
                        The following variables are not supported:
                    </Typography>
                    {unsupportedVariableList.map((variable, index) => (
                        <Typography key={index} variant="body2" sx={{ color: 'red' }}>
                            {variable}
                        </Typography>
                    ))}
                </div>
            )}
            {variableList.includes("<VocabularyWord1/>") && (
                <TextField
                    label="Vocabulary Word 1"
                    value={vocabularyWord1}
                    onChange={handleVocabularyWord1Change}
                />

            )}
            {variableList.includes("<VocabularyWord2/>") && (
                <TextField
                    label="Vocabulary Word 2"
                    value={vocabularyWord2}
                    onChange={handleVocabularyWord2Change}
                />
            )}
            {variableList.includes("<VocabularyWord3/>") && (
                <TextField
                    label="Vocabulary Word 3"
                    value={vocabularyWord3}
                    onChange={handleVocabularyWord3Change}
                />
            )}
            {variableList.includes("<VocabularyWord4/>") && (
                <TextField
                    label="Vocabulary Word 4"
                    value={vocabularyWord4}
                    onChange={handleVocabularyWord4Change}
                />
            )}
            {variableList.includes("<VocabularyWord5/>") && (
                <TextField
                    label="Vocabulary Word 5"
                    value={vocabularyWord5}
                    onChange={handleVocabularyWord5Change}
                />
            )}
            <Button variant="contained" onClick={handleGenerateAllDialogPlanLlmPromptInParallel} disabled={isGenerating}>
                Generate
                {isGenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
            </Button>
            {generatedDialogPlanResponse != null && (<>

                <TextField
                    label="Generated Dialog Title"
                    value={generatedDialogTitle}
                    onChange={handleGeneratedDialogTitleChange} // Track changes while typing
                    onBlur={handleGeneratedDialogTitleBlur} // Update global state on blur
                />

                {generatedDialogPlanResponse.dialog_plan_llm_prompt_list.map((llm_prompt, index) => (
                    <div style={{ display: 'flex' }} key={`wrapper-${index}`}> {/* Flex container */}
                        <div id={`form-${index}`} style={{ flex: 1, marginRight: '16px' }}> {/* Flex child */}
                            <DialogPlanLlmPromptForm
                                originalDialogPlanLlmPrompt={llm_prompt}
                                handleLlmPromptChange={handleLlmPromptChange}
                                handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt}
                                isRegenerating={isRegenerating[llm_prompt.id] || false} />
                        </div>
                        <div id={`response-${index}`} style={{ flex: 1 }}> {/* Flex child */}
                            <DialogPlanLlmPromptGenerated
                                dialogPrompt={generatedDialogPlanResponse.dialog_prompt_list[index]}
                                handleLlmPromptChange={handleLlmPromptChange}
                                handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt}
                                isRegenerating={isRegenerating[llm_prompt.id] || false} />
                        </div>
                    </div>
                ))}


                <Typography variant="h4">
                    Insert Dialog Prompt into Dialog
                </Typography>

                <FormControl fullWidth>
                    <InputLabel>Put after which Dialog Prompt?</InputLabel>
                    <Select
                        label="Parent Dialog Name"
                        value={insertLessonAndDialogId}
                        onChange={handleSelectChange}
                        error={!isInsertDialogPromptValid} // Set error prop to true if invalid
                    >
                        <MenuItem key={-1} value="">Select a Lesson or Dialog</MenuItem>
                        {courseList.flatMap((course) =>
                            lessonDictionary[course.id]
                                ? lessonDictionary[course.id].flatMap((lesson) => [
                                    <MenuItem key={-lesson.id} value={`${lesson.id}-`}>
                                        {course.name} :: {lesson.name} :: First
                                    </MenuItem>,
                                    dialogDictionary[lesson.id]
                                        ? dialogDictionary[lesson.id].map((dialog) => (
                                            <MenuItem key={dialog.id} value={`${lesson.id}-${dialog.id}`}>
                                                {course.name} :: {lesson.name} :: {dialog.title}
                                            </MenuItem>
                                        ))
                                        : []
                                ])
                                : []
                        )}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={handleInsertDialogPrompt} disabled={isInserting}>
                    Insert
                    {isInserting && <CircularProgress size={16} sx={{ ml: 2 }} />}
                </Button>
            </>)}
        </Stack >
    </>);
};

export default DialogPlanForm;
