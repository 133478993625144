import { Box, Button, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogPlanLlmPrompt } from '../../api/DialogPlanLlmPromptApi';
import { DialogPromptSpeakNoGrade } from '../../api/DialogPromptApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';

interface DialogPlanLlmPromptSpeakNoGradeGeneratedProps {
    dialogPrompt?: DialogPromptSpeakNoGrade; // if llmPrompt was generated
    onSubmit?: (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete?: (dialogPlanLlmPromptId: number) => void;

    isRegenerating?: boolean;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
}

const DialogPlanLlmPromptSpeakNoGradeGenerated: React.FC<DialogPlanLlmPromptSpeakNoGradeGeneratedProps> = ({ dialogPrompt, onSubmit, onDelete, isRegenerating, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState("");
    const [studentResponsePrompt, setStudentResponsePrompt] = React.useState("<Answer/>");

    React.useEffect(() => {
        if (dialogPrompt) {
            setDialogPromptFields(dialogPrompt);
        }
    }, [dialogPrompt])
    function setDialogPromptFields(dialogPrompt: DialogPromptSpeakNoGrade) {
        setTeacherChinesePrompt(dialogPrompt.teacher_chinese_prompt ? dialogPrompt.teacher_chinese_prompt : "")
        setStudentResponsePrompt(dialogPrompt.student_response_prompt ? dialogPrompt.student_response_prompt : "")
    }

    // update dialog prompt
    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    const handleStudentResponsePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponsePrompt(e.target.value); };

    return (<>
        <Stack spacing={4} sx={{ width: '100%', margin: '0 auto', pt: 2, pb: 4 }}>
            <TextField
                label="Teacher Chinese Prompt"
                value={teacherChinesePrompt}
                onChange={handleTeacherChinesePromptChange}
                multiline
                rows={5}
            />
            {/* Hidden spacer button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
            <div>
                <TextField
                    label="Student Chinese Prompt"
                    value={studentResponsePrompt ? studentResponsePrompt : "<Answer/>"}
                    onChange={handleStudentResponsePromptChange}
                    required
                    sx={{ width: '100%' }}
                    multiline
                    rows={5}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted
                </Typography>
                {/* Hidden spacer button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        disabled={true}
                        sx={{ opacity: 0 }}
                    >
                        Regenerate
                    </Button>
                </Box>
            </div>
        </Stack>
    </>);
};

export default DialogPlanLlmPromptSpeakNoGradeGenerated;