import { Button, Stack } from '@mui/material';
import React from 'react';
import { DialogPromptSelectTeacher } from '../../api/DialogPromptApi';
import ChatBubble from '../ChatBubble';

import { useHistory } from 'react-router-dom';
import { SentenceApi } from '../../api/SentenceApi';
import { IUser, UserApi } from '../../api/UserApi';
import female_avatar_1 from '../../asset/female_avatar_1.jpg';
import female_avatar_2 from '../../asset/female_avatar_2.jpg';
import male_avatar from '../../asset/male_avatar.jpg';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import { getAudioByText } from './DialogPromptComponent';

interface DialogPromptSelectTeacherComponentProps {
    dialogPrompt: DialogPromptSelectTeacher;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptSelectTeacherComponent: React.FC<DialogPromptSelectTeacherComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const [response, setResponse] = React.useState<string | null>(null);
    const [responseAudio, setResponseAudio] = React.useState<Blob | null>(null);

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const history = useHistory();
    const maleResponse = "你好，我是史老师"
    const femaleResponse = "你好，我是史老师"
    const female2Response = "你好，我是史老师"

    const handleMale = async () => {
        localStorage.setItem('teacher', 'male');
        const sentenceRespose = await SentenceApi.createOrUpdate(maleResponse)
        setResponse(maleResponse)
        if (maleAudio != undefined) {
            setResponseAudio(maleAudio)
        }
        // playAudio(sentenceRespose.data.id, 'male')
    }

    const handleFemale = async () => {
        localStorage.setItem('teacher', 'female');
        const sentenceRespose = await SentenceApi.createOrUpdate(femaleResponse)
        setResponse(femaleResponse)
        if (femaleAudio != undefined) {
            setResponseAudio(femaleAudio)
        }
        // playAudio(sentenceRespose.data.id, 'female')
    }

    const handleFemale2 = async () => {
        localStorage.setItem('teacher', 'female2');
        const sentenceRespose = await SentenceApi.createOrUpdate(female2Response)
        setResponse(female2Response)
        if (female2Audio != undefined) {
            setResponseAudio(female2Audio)
        }
        // playAudio(sentenceRespose.data.id, 'female2')
    }

    const handleConfirm = () => {
        setDialogPromptComplete(dialogPrompt.id);
        const selectedTeacher = localStorage.getItem('teacher');
        if (selectedTeacher) {
            updateDbTeacher(selectedTeacher)
        }
    };

    const updateDbTeacher = (teacher: string) => {
        const updatedUser: IUser = {
            chinese_teacher: teacher,
        };
        // Call the update function
        UserApi.update(updatedUser)
            .then(response => {
                // Handle the response
                // console.log('User updated:', response.data);
            })
            .catch(error => {
                // Handle the error
                console.error(error);
                setErrorMessage('Error updating user:' + error.message);
            });
    }

    // function playAudio(sentenceId: number, teacher: string) {
    //     let audioPromise;

    //     if (teacher == "male") {
    //         audioPromise = AudioApi.getMale(sentenceId)
    //     } else if (teacher == 'female') {
    //         audioPromise = AudioApi.getFemale(sentenceId)
    //     } else if (teacher == 'female2') {
    //         audioPromise = AudioApi.getFemale2(sentenceId)
    //     } else {
    //         throw Error("unknown selected teacher name in local storage " + teacher);
    //     }

    //     audioPromise
    //         .then(response => {
    //             // check if the response was successful
    //             if (response.status >= 300) {
    //                 throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
    //             }
    //             return response.data;
    //         })
    //         .then(blob => {
    //             // create a new Audio object and set the source to the blob URL
    //             const audio = new Audio(URL.createObjectURL(blob));
    //             audio.play();
    //         })
    //         .catch(err => {
    //             if (err.response && err.response.status === 401) {
    //                 history.push('/login');
    //             } else {
    //                 console.error(err);
    //                 setErrorMessage(err.message);
    //             }
    //         });
    // };

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [maleAudio, setMaleAudio] = React.useState<Blob | null | undefined>(undefined);
    const [femaleAudio, setFemaleAudio] = React.useState<Blob | null | undefined>(undefined);
    const [female2Audio, setFemale2Audio] = React.useState<Blob | null | undefined>(undefined);

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
        getAudioByText(history, setErrorMessage, maleResponse, setMaleAudio)
        getAudioByText(history, setErrorMessage, femaleResponse, setFemaleAudio)
        getAudioByText(history, setErrorMessage, female2Response, setFemale2Audio)
    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center' }}>
                    <img src={male_avatar} alt="Male" onClick={() => handleMale()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <img src={female_avatar_1} alt="Female 1" onClick={() => handleFemale()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <img src={female_avatar_2} alt="Female 2" onClick={() => handleFemale2()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <Button variant="contained" onClick={handleConfirm} style={{ marginLeft: '1em' }}>
                        Confirm
                    </Button>
                </Stack>
            )
        }
    }, [chatBubbleDone]);

    return (
        <Stack>
            {promptAudio != undefined && (
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={dialogPrompt.prompt}
                    messageAudio={promptAudio}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            )}
            {response == null ? null : (
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={response}
                    messageAudio={responseAudio}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            )}
        </Stack>
    );
};

export default DialogPromptSelectTeacherComponent;
